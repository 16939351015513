import {Base64} from "js-base64";
import pako from "pako";
import {marked} from "marked";
import React, {useState, useEffect, useMemo} from "react";
import useStackEdit from "./useStackEdit";
import {useDebouncedCallback} from 'use-debounce';
import useRunOnce from "./useRunOnce";

function deserialize(value) {
    const data = Base64.toUint8Array(value);
    return pako.inflate(data, {to: "string"});
}

function serialize(value) {
    if (value === "") {
        return "";
    }
    const data = new TextEncoder().encode(value);
    const compressed = pako.deflate(data, {level: 9});
    return Base64.fromUint8Array(compressed, true);
}

function retrieveValue() {
    const hash = window.location.hash;
    if (hash === "" || hash === undefined) {
        return "";
    }
    return deserialize(hash.substring(1));
}


function serializeHash(value) {
    const newHash = serialize(value);
    window.location.hash = "#" + newHash
}


const copyToClipboard = async () => {
    try {
        alert("Copied to clipboard")
        await navigator.clipboard.writeText(window.location);
        // Optional: Display a success message to the user
    } catch (error) {
        console.error("Failed to copy to clipboard:", error);
        // Optional: Display an error message to the user
    }
};


export default function App() {
    const [value, setValue] = useState(retrieveValue());
    const {openStackedit} = useStackEdit(setValue);
    const markdown = useMemo(() => marked.parse(value), [value]);
    const debouncedSerialize = useDebouncedCallback(() => {
        serializeHash(value)
    }, 500);

    useEffect(() => {
        debouncedSerialize()
    }, [value, debouncedSerialize]);

    useRunOnce({
        fn: () => {
            if (value === "") {
                openStackedit({content: {text: "# Welcome!\n\nWhen you type, we compress and save into the URL. \n\nYou can write an entire essay and share the link. \n\nEverything is easily saved in the url."}});
            } else {
                openStackedit({content: {text: value}});
            }
        },
    });

    return (
        <div className="container-fluid py-3">
            <div className="row">
                <div className="col-12 col-sm-6 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h1 className="input-group-text text-center d-block">{document.title}</h1>
                            <div className="input-group input-group-lg">
                                <button
                                    className={"btn btn-outline-primary col-6"}
                                    onClick={() => {
                                        openStackedit({content: {text: value}});
                                    }}
                                >Open Editor
                                </button>
                                <button
                                    title={'copy to clipboard'}
                                    className={'col-6 btn btn-outline-primary'}
                                    onClick={copyToClipboard}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-clipboard-fill" viewBox="0 0 16 16">
                                        <path fillRule="evenodd"
                                              d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"/>
                                    </svg>
                                    Copy Share Link:
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div dangerouslySetInnerHTML={{__html: markdown}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
